import axios from "axios";
import { endpoints } from "../_config";

class UserService {
  /**
   * Get All Users Api
   * @param {*}
   * @returns
   */
  static async getUsers(user) {
    return axios
      .get(endpoints.user.getList, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Add User Api
   * @param {*}
   * @returns
   */
  static async addUser(user, data) {
    return axios
      .post(endpoints.user.add, data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Add Bulk Users Api (using csv file)
   * @param {*}
   * @returns
   */
  static async addBulkUsers(user, file, orgId) {
    return axios
      .post(endpoints.user.addBulkUsers(orgId), file, {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  /**
   * Update User Api
   * @param {*}
   * @returns
   */
  static async updateUser(user, userId, data) {
    return axios
      .put(endpoints.user.edit(userId), data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Delete User Api
   * @param {*}
   * @returns
   */
  static async deleteUser(user, userId) {
    return axios
      .delete(endpoints.user.delete(userId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get User Profile Api
   * @param {*}
   * @returns
   */
  static async getUserProfile(token) {
    return axios
      .get(endpoints.user.profile, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get User Profile Api
   * @param {*}
   * @returns
   */
  static async getOrganizationUsers(user, orgId) {
    return axios
      .get(endpoints.user.getOrgUsers(orgId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
}

export { UserService };
