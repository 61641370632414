import axios from "axios";
import { endpoints } from "../_config";

class OrganizationService {
  /**
   * Add Organization Api
   * @param {*}
   * @returns
   */
  static async addOrganization(user, data) {
    return axios
      .post(endpoints.org.add, data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get all Organizations
   * @param {*} Bearer token
   * @returns
   */
  static async getAllOrganizations(user) {
    return axios
      .get(endpoints.org.getAllOrganization, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get update organization
   * @param {*} Bearer token
   * @returns
   */
  static async updateOrganization(user, orgId, data) {
    return axios
      .put(endpoints.org.edit(orgId), data, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get delete organization
   * @param {*} Bearer token
   * @returns
   */
  static async deleteOrganization(user, orgId) {
    return axios
      .delete(endpoints.org.delete(orgId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get Organization Details
   * @param {*} Bearer token
   * @returns
   */
  static async getOrganizationDetail(user, orgId) {
    return axios
      .get(endpoints.org.getOrganizationDetails(orgId), {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Get Organization Specific Users
   * @param {*} user
   * @param {*} orgId
   * @returns
   */
  static async getOrganizationUsers(user, orgId) {
    return axios
      .delete(endpoints.org.getOrganizationUsers, orgId, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }

  /**
   * Upload Image Service
   * @param {*} image file
   * @returns
   */
  static async uploadImage(user, file) {
    return axios
      .post(endpoints.imageUpload.fileUpload, file, {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  }

  static async getOrganizationByToken(user) {
    return axios
      .get(endpoints.org.getOrganizationByToken, {
        headers: {
          Authorization: "Bearer " + user.token,
        },
      })
      .then((response) => response.data);
  }
}

export { OrganizationService };
