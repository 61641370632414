import { applyMiddleware, compose, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { rootReducer } from "./_reducers";

// prepare the default state
const state = {
  auth: { token: JSON.parse(localStorage.getItem("token")) },
};

// prepare the store
let composeEnhancers;
const middlewares = [thunk];
if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  middlewares.push(logger);
} else {
  composeEnhancers = compose;
}

export const store = createStore(
  rootReducer,
  state,
  composeEnhancers(applyMiddleware(...middlewares))
);
