export const SET_ORGANIZATION = "SET_ORGANIZATION";
export const DESTROY_ORGANIZATION = "DESTROY_ORGANIZATION";

/**
 * Set organization Id to state
 * @param {*} organization details
 * @returns
 */
export function setOrganization(orgData) {
  return {
    type: SET_ORGANIZATION,
    orgData,
  };
}

/**
 * Remove the organization Id from state
 * @returns
 */
export function destroyOrganization() {
  return {
    type: DESTROY_ORGANIZATION,
  };
}
