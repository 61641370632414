import {
  DESTROY_ORGANIZATION,
  SET_ORGANIZATION,
} from "../_actions/organization.actions";

/**
 * Organization Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function orgReducer(state = null, action) {
  switch (action.type) {
    case SET_ORGANIZATION:
      return action.orgData;
    case DESTROY_ORGANIZATION:
      return null;
    default:
      return state;
  }
}
