import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./_store";
import { Provider } from "react-redux";
import { ToastProvider } from "./contexts/toastr.context";
import "./_styles/common.style.scss";
import "./_styles/main.style.scss";
import { configureAxios } from "./_config/axios.config";
// import Cohere from "cohere-js";

configureAxios();

// initialize cohere

// if (window.location.port === "") {
//   Cohere.init(process.env.REACT_APP_COHERE_KEY);
// }

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastProvider>
        <App />
      </ToastProvider>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// remove the initial loader, hardcoded at index.html
// this loader show up before initial js load, and is removed as soon as js load is complete
const loader = document.getElementById("loader");
loader.parentElement.removeChild(loader);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
