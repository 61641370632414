import axios from "axios";

export function configureAxios() {
  axios.interceptors.response.use(undefined, function (error) {
    if (error.response.status === 401) {
      // clear the session
      localStorage.removeItem("vitalize.user");
    }
    return Promise.reject(error);
  });
}
