const base = process.env.REACT_APP_BASE_URL;

const endpoints = {
  auth: {
    signin: base + "auth/signin",
    signup: base + "auth/signup",
    resetPassword: (token) => base + "auth/reset/" + token,
    forgot: base + "auth/forgot",
  },
  org: {
    add: base + "add-organization",
    edit: (organizationId) => base + "update-organization/" + organizationId,
    delete: (organizationId) => base + "delete-organization/" + organizationId,
    getAllOrganization: base + "get-organizations",
    getOrganizationDetails: (orgId) =>
      base + "get-single-organization/" + orgId,
    getOrganizationUsers: (organizationId) => base + "" + organizationId,
    getOrganizationByToken: base + "get-single-organization-for-org-admin",
  },
  user: {
    add: base + "users/invite-user",
    edit: (userId) => base + "users/update-user/" + userId,
    delete: (userId) => base + "users/delete-user/" + userId,
    getList: base + "users/all-users",
    profile: base + "users/get-profile/me",
    getOrgUsers: (orgId) => base + "users/get-organization-users/" + orgId,
    addBulkUsers: (orgId) => base + "users/upload-csv/" + orgId,
  },
  profile: {
    own: base + "me",
  },
  dashboard: {
    all: base + "organization-dashboard",
    orgAdmin: base + "organization-dashboard-for-orgAdmin",
  },
  imageUpload: {
    fileUpload: base + "upload-files",
  },
  announcement: {
    add: base + "announcement",
    getAll: base + "notifications",
  },
  feedback: {
    getFeedbacks: base + "get-feedbacks",
  },
  track: {
    getAll: base + "get-tracks",
    editTrack: (trackId) => base + "update-track/" + trackId,
    deleteTrack: (trackId) => base + "delete-track/" + trackId,
    addTrack: base + "add-track",
  },
  surveys: {
    getAllSurveys: base + "survey",
    create: base + "survey",
    getSurvey: (surveyId) => base + "survey/" + surveyId,
  },
  community: {
    add: base + "peer/group",
    getOrganizationGroups: (orgId) => base + "peer/group/" + orgId,
    getGroupDetails: (groupId) => base + "single/peer/group/" + groupId,
    update: (groupId) => base + "update/peer/group/" + groupId,
    delete: (groupId) => base + "peer/group/" + groupId,
  },
  mixpanel: {
    getAllData: base + "mixpanel-dashboard",
    getKpiData: base + "mixpanel-kpi-data",
    getGraphData: base + "mixpanel-graph-data",
    getProfilePropertyData: base + "mixpanel-profile-property-data",
    getEventPropertyData: base + "mixpanel-event-property-data",
  },
};

export { endpoints };
